<template>
	<div :class="$route.name=='bkcode'?'top grey':'top'">
		<div class="header  flex_between">
			<div class="flex_start flex_1" style="height: 100%">
				<img class="logo" :src="(login_mess.gsiId==15&&this.login_mess.identity!=3&&this.login_mess.identity!=6)?basic_static + 'learnspace/images/home/lx-logo.png':basic_static + 'learnspace/images/home/logo.png'" />
				<ul class="flex_start menu-ul flex_1" v-if="$route.name!='bkcode'&&$route.name!='bkexplain'">
					<li class="flex_center text_16" v-for="item in menu_list" @click="changeMenu(item)" 
						:class="menu_id == item.id ? 'active' : ''">
						{{ item.title }}
					</li>
				</ul>
			</div>
			<div v-if="token&&login_mess.userName&&$route.name!='bkcode'&&$route.name!='bkexplain'" class="flex_start">
				<el-popover
				v-if="login_mess.identity !=0"
  placement="bottom"
  width="400"
  :disabled="notice_list.length==0"
  trigger="click">
  <ul class="notice_ul">
	<li class="flex_start" v-for="(item,index) in notice_list" @click="readList(item,index)">
		<img  src="https://cdn.tqxxkj.cn/static/learnspace/images/notice-list.png"  alt="logo" />
		<div>
			<p class="text_14 grey1">{{item.content}}</p>
			<p class="text_12 grey3">{{item.createTime}}</p>
		</div>
	</li>
  </ul>
  <img slot="reference" :src="notice_list.length>0?'https://cdn.tqxxkj.cn/static/learnspace/images/notice.png':'https://cdn.tqxxkj.cn/static/learnspace/images/notice-no.png'" class="notice" alt="logo" />

</el-popover>
				<el-dropdown >
				<div class="el-dropdown-link flex_start head">
					<img src="https://cdn.tqxxkj.cn/static/learnspace/images/home/default-head.png" alt="logo" />
					<p class="text_14_bold">{{ login_mess.userName }}</p>
					<i class="el-icon-caret-bottom el-icon--right" style="color: #c2c2c2" ></i>
				</div>
				<el-dropdown-menu slot="dropdown" >
					<el-dropdown-item @click.native="dialogVisible = true">切换身份</el-dropdown-item>
					<el-dropdown-item @click.native="$router.push('/set/tsmaintain')"
						v-if="login_mess.identity == 3||login_mess.identity == 4">特色课程维护</el-dropdown-item>
						<el-dropdown-item @click.native="$router.push('/set/jtx')"
						v-if="login_mess.identity == 3">集团校维护</el-dropdown-item>
						<el-dropdown-item @click.native="$router.push('/set/mlgl')"
						v-if="login_mess.identity == 3">目录维护</el-dropdown-item>
					<el-dropdown-item @click.native="$router.push('/set/admin')"
						v-if="login_mess.identity == 3||login_mess.identity == 4">权限设置</el-dropdown-item>
					<el-dropdown-item @click.native="$router.push('/set/group')" v-if="login_mess.identity == 2||login_mess.identity == 6">备课组管理</el-dropdown-item>
						<el-dropdown-item @click.native="dialogVisibleSfbd=true" v-if="login_mess.identity != 0" >身份绑定</el-dropdown-item>
					<el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			</div>
			<div class="no-login flex_center" @click="toLogin" v-if="!token||!login_mess.userName">
				<img class="head-img" :src="basic_static + 'learnspace/images/home/head.png?v=1'" />
				<p class="text_14_bold">登录</p>
			</div>
			<div class="el-dropdown-link flex_start head" v-if="token&&($route.name=='bkcode'||$route.name=='bkexplain')">
				<img src="https://cdn.tqxxkj.cn/static/learnspace/images/home/default-head.png" alt="logo" />
				<p class="text_14_bold">{{ login_mess.userName }}</p>
			</div>
			<el-dialog :close-on-click-modal="false" :visible.sync="$store.state.user.login_dialog" class="login-dialog" width="456px" v-if="$store.state.user.login_dialog" @before-close="beforeClose" 
				:modal-append-to-body="false">
				<Login  @setPsw="setPsw" @close="close"></Login>
			</el-dialog>
			<el-dialog title="切换身份" :visible.sync="dialogVisible" width="456px" :modal-append-to-body="false" class="zdy-dialog" >
				<Role @close="close"></Role>
			</el-dialog>
			<el-dialog :visible.sync="dialogVisibleForget" width="456px" v-if="dialogVisibleForget" class="login-dialog" 
				:modal-append-to-body="false">
				<Forget @close="close"></Forget>
			</el-dialog>
			<el-dialog title="身份绑定" :visible.sync="dialogVisibleSfbd" width="456px" v-if="dialogVisibleSfbd" :modal-append-to-body="false" class="zdy-dialog" >
				<RoleBind @close="close"></RoleBind>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import Role from "./Role.vue";
	import Login from "./Login.vue";
	import Forget from "./Forget.vue";
	import RoleBind from './RoleBind.vue'
	import { clearCookie, getCookie, noToken } from "../util/index";
	import { mapGetters } from "vuex";
	import { getInfoDetail, userLogout,websiteMessage,websiteMessageRead } from "../api/login";
	import { setCookie } from "../util/index";
	export default {
		components: { Role, Login, Forget,RoleBind },
		data() {
			return {
				activeIndex: "1",
				dialogVisible: false,
				token: null,
				tab_id: "",
				dialogVisibleForget: false,
				dialogVisibleLogin:false,
				dialogVisibleSfbd:false,
				// menu_list: [
				// 	{
				// 		id: 1,
				// 		title: "首页",
				// 		url: "/homepage/index",
				// 		show:true,
				// 		type:'current'
				// 	},
				// 	{
				// 		id: 2,
				// 		title: "数字化教学",
				// 		url: "/space/my",
				// 		show:true,
				// 		type:'current'
				// 	},
				// 	{
				// 		id: 3,
				// 		title: "资源中心",
				// 		url: "/resource/index",
				// 		show:true,
				// 		type:'current'
				// 	},
				// 	{
				// 		id: 4,
				// 		title: "综合素质评价",
				// 		show:true,
				// 		type:'open',
				// 		url: "https://apps.tq-edu.com/overall_merit/#/activity/manage?synthesizejudge_token=eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImY0MTViY2EzLTgyOTAtNDUxYy1iODMzLTllMDhiYTg1Njk5YiJ9.FQpIY_w4WBJ_vdKla8eIvP9DLK939-m8z9o5Zz71A6PSiMzgsbeiQ_CITj7MGW25iMSc4kTbZyrJz8kRNMrveg",
				// 	},
				// 	{id:5,title:'OA应用中心',url:'/application',show:true,type:'current',},
				// 	{ id: 6, title: "双线课堂",show:true, },
				// 	{ id: 7, title: "数据管理", url: "https://dc.glzhxx.com/#/landpage",show:true,type:'open', },
				// ],
				menu_id: '1',
				notice_list:[]
			};
		},
		mounted() {
		// 	if (document.documentElement.clientWidth < 1350) {
		// 					$(".logo").css({ "margin-right": "5%" });
		// 				}else if (document.documentElement.clientWidth < 1150) {
		// 					$(".logo").css({ "margin-right": "2%" });
		// 				} 
		// 				 else {
		// 					$(".logo").css({ "margin-right": "136px" });
		// 				}
		// 	window.addEventListener('resize', () => {
		// 				if (document.documentElement.clientWidth < 1350) {
		// 					$(".logo").css({ "margin-right": "5%" });
		// 				}
		// 				if (document.documentElement.clientWidth > 1350) {
		// 					$(".logo").css({ "margin-right": "136px" });
		// 				}
		// 				 if (document.documentElement.clientWidth < 1150) {
		// 					console.log(222222)
		// 					$(".logo").css({ "margin-right": "2%" });
		// 				} 
		// });

			// window.onresize = function () {
			// 	if (document.documentElement.clientWidth < 1380) {
			// 		$(".logo").css({ "margin-right": "2%" });
			// 	} else {
			// 		$(".logo").css({ "margin-right": "136px" });
			// 	}
			// 	console.log(1111111)
			// };
		},
		watch: {
			$route: {
				handler: function (val, oldVal) {
					if (this.$route.path=='/application/front/ai') {
						this.menu_id = 10;
					}else if (this.$route.path.indexOf("application") > -1) {
						this.menu_id = 5;
					} else if (this.$route.path.indexOf("space") > -1) {
						this.menu_id = 2;
					} else if (this.$route.path.indexOf("resource") > -1) {
						this.menu_id = 3;
					}else if (this.$route.path.indexOf("screen") > -1) {
						this.menu_id = 9;
					}  else {
						this.menu_id = 1;
					}
				},
				// 深度观察监听
				deep: true,
			},
			'login_mess.identity': {
				handler: function (val, oldVal) {
					let menu_list=[]
					if(this.login_mess.identity==1){
						menu_list=[
							{
								id: 1,
								title: "首页",
								url: "/homepage/index",
								show:true,
								type:'current'
							},
							{
								id: 2,
								title: "数字化教学",
								url: "/space/my",
								show:true,
								type:'current'
							},
							{
								id: 3,
								title: "资源中心",
								url: "/resource/index",
								show:true,
								type:'current'
							},
							{id:5,title:'OA应用中心',url:'/application',show:true,type:'current',},
							{
								id: 10,
								title: "AI助手中心",
								show:true,
								type:'current',
								url: '/application/front/ai',
							},
							{ id: 6, title: "双线课堂",show:true, },
							{ id: 8, title: "小学教育集团数智平台", url: "https://jtxkh.glzhxx.com/#/login?wwCorpId="+this.login_mess.corpId+'&wwUserId='+this.login_mess.wxUserId+'&authenticationRole=generalTeacher&gsiId='+this.login_mess.gsiId+'&groupSchoolId='+this.login_mess.groupSchoolId,show:true,type:'open', },
						]
						if(this.login_mess.gsiId == 1 || this.login_mess.gsiId == 15){ //1光华，15拉小
							menu_list.push({ id: 9, title: "数据驾驶舱", url: this.login_mess.gsiId == 1 ? "/screen/index" : "/screen/home",show:true,type:'current', })
						}
					}
					if(this.login_mess.identity==2){
						menu_list=[
							{
								id: 1,
								title: "首页",
								url: "/homepage/index",
								show:true,
								type:'current'
							},
							{
								id: 2,
								title: "数字化教学",
								url: "/space/my",
								show:true,
								type:'current'
							},
							{
								id: 3,
								title: "资源中心",
								url: "/resource/index",
								show:true,
								type:'current'
							},
							{id:5,title:'OA应用中心',url:'/application',show:true,type:'current',},
							{ id: 6, title: "双线课堂",show:true, },
							{
								id: 10,
								title: "AI助手中心",
								show:true,
								type:'current',
								url: '/application/front/ai',
							},
							// {
							// 	id: 4,
							// 	title: "综合素质评价",
							// 	show:true,
							// 	type:'open',
							// 	url: "https://www.glzhxx.com/zhszpj/admin/#/login?corpId="+this.login_mess.corpId+'&wxUserId='+this.login_mess.wxUserId,
							// },
							{ id: 8, title: "小学教育集团数智平台", url: "https://jtxkh.glzhxx.com/#/login?wwCorpId="+this.login_mess.corpId+'&wwUserId='+this.login_mess.wxUserId+'&authenticationRole=schoolAdmin&gsiId='+this.login_mess.gsiId+'&groupSchoolId='+this.login_mess.groupSchoolId,show:true,type:'open', },
						]
						if(this.login_mess.gsiId == 1 || this.login_mess.gsiId == 15){ //1光华，15拉小
							menu_list.push({ id: 9, title: "数据驾驶舱", url: this.login_mess.gsiId == 1 ? "/screen/index" : "/screen/home",show:true,type:'current', })
						}
					}
					if( this.login_mess.identity==3||!this.login_mess.identity){
						menu_list=[
							{
								id: 1,
								title: "首页",
								url: "/homepage/index",
								show:true,
								type:'current'
							},
							{
								id: 2,
								title: "数字化教学",
								url: "/space/my",
								show:true,
								type:'current'
							},
							{
								id: 3,
								title: "资源中心",
								url: "/resource/index",
								show:true,
								type:'current'
							},
							// {
							// 	id: 4,
							// 	title: "综合素质评价",
							// 	show:true,
							// 	type:'open',
							// 	url: "https://www.glzhxx.com/zhszpj/admin/#/login?corpId="+this.login_mess.corpId+'&wxUserId='+this.login_mess.wxUserId,
							// },
							{id:5,title:'OA应用中心',url:'/application',show:true,type:'current',},
							{
								id: 10,
								title: "AI助手中心",
								show:true,
								type:'current',
								url: '/application/front/ai',
							},
							{ id: 6, title: "双线课堂",show:true, },
							{ id: 7, title: "数据管理", url: "https://dc.glzhxx.com/#/landpage?corpId="+this.login_mess.corpId+'&identity='+this.login_mess.identity,show:true,type:'open', },
							{ id: 8, title: "小学教育集团数智平台", url: "https://jtxkh.glzhxx.com/#/login?wwCorpId="+this.login_mess.corpId+'&wwUserId='+this.login_mess.wxUserId+'&authenticationRole=areaAdmin&gsiId='+this.login_mess.gsiId+'&groupSchoolId='+this.login_mess.groupSchoolId,show:true,type:'open', },
							// { id: 8, title: "数据大屏", url: "/screen/index",show:true,type:'open', },
							
						]
					}
					if( this.login_mess.identity==4||this.login_mess.identity==0||this.login_mess.identity==5){
						menu_list=[
							{
								id: 1,
								title: "首页",
								url: "/homepage/index",
								show:true,
								type:'current'
							},
							{
								id: 2,
								title: "数字化教学",
								url: "/space/my",
								show:true,
								type:'current'
							},
							{
								id: 3,
								title: "资源中心",
								url: "/resource/index",
								show:true,
								type:'current'
							},
							{
								id: 10,
								title: "AI助手中心",
								show:true,
								type:'current',
								url: '/application/front/ai',
							},
						]
					}
					if( this.login_mess.identity==6){
						menu_list=[
							{
								id: 1,
								title: "首页",
								url: "/homepage/index",
								show:true,
								type:'current'
							},
							{
								id: 2,
								title: "数字化教学",
								url: "/space/my",
								show:true,
								type:'current'
							},
							{
								id: 3,
								title: "资源中心",
								url: "/resource/index",
								show:true,
								type:'current'
							},
							{
								id: 10,
								title: "AI助手中心",
								show:true,
								type:'current',
								url: '/application/front/ai',
							},
							{ id: 8, title: "小学教育集团数智平台", url: "https://jtxkh.glzhxx.com/#/login?wwCorpId="+this.login_mess.corpId+'&wwUserId='+this.login_mess.wxUserId+'&authenticationRole=groupSchoolAdmin&groupSchoolId='+this.login_mess.groupSchoolId+'&gsiId='+this.login_mess.gsiId,show:true,type:'open', },
						]
					}
					// if((this.login_mess.identity==2||this.login_mess.identity==1||this.login_mess.identity==3||this.login_mess.identity==6)&&this.login_mess.stageId==1){
					// 		menu_list.push({ id: 8, title: "小学教育集团数智平台", url: "https://jtxkh.glzhxx.com/#/login?wwCorpId="+this.login_mess.corpId+'&wwUserId='+this.login_mess.wxUserId+'&authenticationRole=generalTeacher&gsiId='+this.login_mess.gsiId+'&groupSchoolId='+this.login_mess.groupSchoolId,show:true,type:'open', })
					// 	}
					this.$store.commit('set_menu_list',menu_list)
					if(!this.login_mess.identity&&this.login_mess.identity!=0){
						this.token=null
					}else{
						this.token = getCookie("access-token");
						this.websiteMessage()
					}
				},
				// 深度观察监听
				deep: true,
			},
		},
		async created() {
			this.token = getCookie("access-token");
			if (this.token) {
				await this.getInfoDetail();
			}else{
				this.$store.commit('set_login_mess',{})
			}
			if (this.$route.path=='/application/front/ai') {
				this.menu_id = 10;
			} else if (this.$route.path.indexOf("application") > -1) {
				this.menu_id = 5;
			} else if (this.$route.path.indexOf("space") > -1) {
				this.menu_id = 2;
			}else if (this.$route.path.indexOf("screen") > -1) {
				this.menu_id = 9;
			} else if (this.$route.path.indexOf("resource") > -1) {
				this.menu_id = 3;
			}else {
				this.menu_id = 1;
			}
			// if(this.login_mess.identity==1){
			// 	this.menu_list[6].show=false
			// 	this.menu_list[3].show=false
			// }
			// if(this.login_mess.identity==2){
			// 	this.menu_list[6].show=true
			// 	this.menu_list[3].show=false
			// }
			// if(this.login_mess.identity==3){
			// 	this.menu_list[6].show=true
			// }
			// if(this.login_mess.identity==0){
			// 	this.menu_list[3].show=false
			// 	this.menu_list[6].show=false
			// 	this.menu_list[4].show=false
			// 	this.menu_list[5].show=false
			// }
			this.$store.commit('set_login_dialog',false)
		},
		methods: {
			async readList(item,index){
				let data={
					websiteMessageId:item.websiteMessageId
				}
				await websiteMessageRead(data)
				this.notice_list.splice(index,1)
				// 0:资源；1:备课；2:应用；3：个人空间
				if(item.module==1){
					if(item.roleType=='apply'){
						this.$router.push('/resource/detail/'+item.dataId)
					}else{
						this.$router.push(`/space/audit/detail/${item.verifyDataId}/resource`)
					}

				}else if(item.module==0){
					if(item.roleType=='apply'){
						this.$router.push('/resource/detail/'+item.dataId)
					}else{
						this.$router.push(`/space/audit/detail/${item.verifyDataId}/resource`)
					}

				}else if(item.module==2){
					if(item.roleType=='apply'){
						this.$router.push('/application/admin/apply')
					}else{
						this.$router.push('/application/admin/audit')
					}

				}
				else if(item.module==3){
					if(item.roleType=='apply'){
						this.$router.push('/space/bk/myspacedetail/'+item.dataId)
					}else{
						this.$router.push(`/space/audit/detail/${item.verifyDataId}/bk`)
					}

				}
			},
			async websiteMessage(){
				let res=await websiteMessage()
				if(res){
					this.notice_list=res.data
				}
			},
			beforeClose(){
				this.$store.commit('set_login_dialog',false)
			},
			toLogin(){
				this.$store.commit('set_login_dialog',true)
			},
			async getInfoDetail() {
				let res = await getInfoDetail();
				if (res) {
					localStorage.setItem("userInfo", JSON.stringify(res.data));
					this.$store.commit("set_login_mess", res.data);
					setCookie("userId", res.data.userId + "");
					// res.data.identitys.forEach((item) => {
					//     if (item.isCurrent == 1) {
					//         // this.login_mess=item
					//         this.$store.commit("set_login_mess", item);
					//     }
					// });
				}
			},
		   /**
     * uri 打开客户端的uri
     * failCb 打开客户端失败回调
     * successCb 打开客户端成功回调
     */
	  openUriWithInputTimeoutHack(uri, failCb, successCb) {
	    let target = document.createElement('input')
	    target.style.width = '0'
	    target.style.height = '0'
    	    target.style.position = 'fixed'
	    target.style.top = '0'
	    target.style.left = '0'
	    document.body.appendChild(target)
	    target.focus();
	    var handler = this._registerEvent(target, "blur", onBlur);
	    console.log('focus')
	    function onBlur() {
		    console.log('blur')
		    successCb && successCb()
		    handler.remove()
		    clearTimeout(timeout)
		    document.body.removeChild(target)
	    };
	    location.href = uri
	    var timeout = setTimeout(function () {
		    failCb && failCb()
		    handler.remove()
		    document.body.removeChild(target)
	    }, 1000);
    },
 
     _registerEvent(target, eventType, cb) {
        if (target.addEventListener) {
            target.addEventListener(eventType, cb);
            return {
                remove: function () {
                    target.removeEventListener(eventType, cb);
                }
            };
        } else {
            target.attachEvent(eventType, cb);
            return {
                remove: function () {
                    target.detachEvent(eventType, cb);
                }
            };
        }
    },
    

			changeMenu(item) {
				if (this.menu_id == 1 && item.id == 1&&this.$router.path=='/homepage/index') return;
				if (item.id == 1) {
					// window.location.href = item.url;
					this.$router.push(item.url)
					return;
				}
				if (noToken()) {
					console.log(item.url)
					if (item.id == 6) {
						let protocalUrl = `mskzkt-edu-teacher://`
						this.openUriWithInputTimeoutHack(protocalUrl, () => {
							alert('未安装双线课堂')
						})
						return;
					}
					// if(item.id==7){
                    //     window.open(item.url+'?corpId='+this.login_mess.corpId+'&identity='+this.login_mess.identity)
                    //     return
                    // }
                    if(item.id==4||item.id==8||item.id==7){
                        window.open(item.url)
                        return
                    }
                    this.menu_id = item.id;
                    if (item.url != "") {
							this.$router.push(item.url)
						}
					}
			},
			setPsw() {
				this.dialogVisibleForget = true;
				this.$store.commit('set_login_dialog',false)
			},
			// async getInfoDetail() {
			//     let res = await getInfoDetail();
			//     if (res) {
			//         localStorage.setItem("userInfo", JSON.stringify(res.data));
			//         this.$store.commit("set_login_mess", res.data)
			//         setCookie('userId',res.data.userId+'')
			//         // res.data.identitys.forEach((item) => {
			//         //     if (item.isCurrent == 1) {
			//         //         // this.login_mess=item
			//         //         this.$store.commit("set_login_mess", item);
			//         //     }
			//         // });
			//     }
			// },
			async loginOut() {
				await userLogout();
                if(this.$route.path=='/homepage/index'){
                    // location.reload();
                }else{
					this.$router.replace('/homepage/index')
                }
				clearCookie();
				localStorage.clear()
				this.$store.commit('set_login_mess',{})
			},
			close(type) {
				this.dialogVisible = false;
				this.dialogVisibleForget=false
				this.dialogVisibleSfbd=false
				if(type){
					this.getInfoDetail()
				}
			},
		},
		computed: {
			...mapGetters(["login_mess", "basic_static",'login_dialog','menu_list']),
		},
	};
</script>

<style lang="scss" scoped>
	@import "../style/element.scss";
	.notice{width: 18px;height: 20px;margin-right: 16px;cursor: pointer;}
	.notice_ul{
		padding: 8px;
		max-height: 400px;
    	overflow-y: auto;
		li{
			margin-bottom: 16px;
			cursor: pointer;
			img{
				width: 28px;
				margin-right: 8px;
			}
		}
		li:last-child{margin-bottom: 0;}
	}
	/*引入公共样式*/
	.top {
		background: $white;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
	}
	.grey{
		background: #F5F7FB ;
	}

	.head {
		padding: 0 16px 0 7px;
		height: 48px;
		border-radius: 24px;
		border: 1px solid $line;

		p {
			color: $grey1;
			white-space: nowrap;
			margin-right: 10px;
		}

		img {
			width: 32px;
			height: 32px;
			margin-right: 8px;
		}
	}

	.header {
		height: 64px;
		max-width: 1280px;
		margin: 0 auto;
		.logo {
			width: auto;
			height:40px;
			margin-right: 40px;
		}

		.menu-ul {
			height: 100%;
			color: $grey1;
			overflow-x: auto;
			margin-right: 24px;
			max-width: calc(100vw - 400px);
			li {
				cursor: pointer;
				border-radius: 8px 8px 0px 0px;
				background: $white;
				height: 100%;
				padding: 0 16px;
				white-space: nowrap;
			}

			li:hover {
				font-weight: bold;
				color: $blue1;
			}

			li.active {
				background: $blue1;
				font-weight: bold;
				color: $white;
			}
		}

		.no-login {
			width: 92px;
			height: 48px;
			background: $white;
			border-radius: 24px;
			border: 1px solid $line;
			color: $grey4;
			cursor: pointer;
			white-space: nowrap;
			.head-img {
				width: 34px;
				height: 34px;
				margin-right: 7px;
			}
		}
	}
</style>